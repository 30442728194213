import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.mypage');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-mypage',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.mypage/view.scss */
.content-page-header {
  display: flex;
  align-items: center;
  padding: 18px 16px;
  padding-top: 0;
}
.content-page-header * {
  margin: 0;
  line-height: 1;
}

.card-body {
  border: none;
}

.alert {
  font-size: 16px;
}

.wiz-form .wiz-form-rows:first-child {
  border-top: none;
}

.wiz-form .wiz-form-rows:last-child {
  border-bottom: none;
}

.btn-save {
  background: #6758F0;
  color: white;
  border-radius: 16px;
}

.btn-edit {
  background: #6758F0;
  color: white;
  border-radius: 16px;
}

.card {
  border-radius: 16px;
}
.card .card-footer {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.delete .btn {
  border: none;
  background: none;
  box-shadow: none;
}
.delete .btn:hover {
  color: #6758F0;
}

.info .title {
  font-size: 16px;
  font-weight: bold;
}
.info .text-highlight {
  color: #Ff3500;
}
.info label {
  font-family: "MAIN-B";
}

.secession {
  margin: 30px auto;
}`],
})
export class PageMypageComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public data: any = {};
    public leave = false;
    public agree = false;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/auth/login");
        await this.load();
    }

    public async alert(message: string, status: any = "error") {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async load() {
        const { data } = await wiz.call("session");

        this.data.user = data;
        this.data.password = {}

        await this.service.render();
    }

    public async update() {
        let userinfo = JSON.stringify(this.data.user);
        const { code, data } = await wiz.call("update", { userinfo });
        if (code == 200) {
            let res = await this.alert("저장되었습니다", 'success');
            if (res) location.reload();
            return;
        }

        await this.alert("오류가 발생했습니다");
    }

    public async changePassword() {
        let pdata = JSON.parse(JSON.stringify(this.data.password));

        if (!pdata.current) {
            await this.alert("현재 비밀번호를 입력해주세요");
            return;
        }
        if (!pdata.data || !pdata.repeat) {
            await this.alert("변경 비밀번호를 입력해주세요");
            return;
        }

        if (pdata.data != pdata.repeat) {
            await this.alert("변경 비밀번호를 다시 확인해주세요");
            return;
        }

        let pd = {};
        pd.current = this.service.auth.hash(pdata.current);
        pd.data = this.service.auth.hash(pdata.data);

        const { code, data } = await wiz.call("change_password", pd);

        if (code != 200) {
            await this.alert(data);
            return;
        }

        location.href = "/auth/logout";
    }

    public async change(tab) {
        this.leave = tab;
        await this.service.render();
    }

    public async check() {
        await this.service.render();
    }

    public async secession() {
        if (this.agree == false) {
            await this.alert("탈퇴 안내 확인 후 동의해 주세요.")
            return;
        }
        let res = await this.alert("계정을 삭제하시겠습니까?");
        if (res) {
            let userinfo = JSON.stringify(this.data.user);
            const { code, data } = await wiz.call("secession", { userinfo });
            if (code !== 200) {
                await this.alert("계정 삭제 중 오류가 발생하였습니다.")
                return;
            }
            await this.alert("계정이 성공적으로 삭제되었습니다.", "success")
            window.location.href = "auth/logout";

        }


    }
}

export default PageMypageComponent;