import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.alert');
import { OnInit, Input, HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-alert',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.season.alert/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-content {
  width: 100%;
  min-width: 280px;
  max-width: 420px;
}`],
})
export class PortalSeasonAlertComponent implements OnInit {
    @Input() model: any = null;

    constructor(@Inject( Service) public service: Service) {
        if (!this.model) this.model = service.alert;
    }

    @HostListener('window:keydown.escape', ['$event'])
    public handler_esc(e) {
        if (!this.model) return;
        if (!this.model.isshow) return;
        this.model.hide();
    }

    @HostListener('window:keydown.enter', ['$event'])
    public handler_enter(e) {
        if (!this.model) return;
        if (!this.model.isshow) return;
        if (document.activeElement) {
            e.preventDefault();
            document.activeElement.blur();
        }
        if (this.model.opts.action) this.model.action();
        else this.model.hide();
    }

    public async ngOnInit() { }
}

export default PortalSeasonAlertComponent;