import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.community');
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-community',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.community/view.scss */
/* src/app/page.community/view.scss: no such file or directory */`],
})
export class PageCommunityComponent implements OnInit {
    private mode = 'list';

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         private route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();

        await this.setting();

        this.router.events.subscribe(async (event: Event) => {
            if (event instanceof NavigationEnd) {
                await this.setting();
            }
        })
    }

    private async setting() {
        this.mode = WizRoute.segment.mode;
        await this.service.render();
    }
}

export default PageCommunityComponent;