import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.dpljpprr');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dataset-graph-dpljpprr',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.dataset.graph.dpljpprr/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

.btn-show {
  height: 34px;
  padding: 8px 35px 8px 35px;
  border-radius: 999px;
  font: normal normal medium 14px/18px SUIT;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  background: #D2CFFB 0% 0% no-repeat padding-box;
  border: 1px solid #4F40F1;
  color: #4F40F1;
}

.btn-none {
  height: 34px;
  padding: 8px 35px 8px 35px;
  border: 1px solid #9496A1;
  border-radius: 999px;
  font: normal normal medium 14px/18px SUIT;
  letter-spacing: 0px;
  background-color: transparent;
  color: #9496A1;
  text-align: center;
  display: flex;
  align-items: center;
}

.btn-none:hover {
  background: #D2CFFB 0% 0% no-repeat padding-box;
  border: 1px solid #4F40F1;
  color: #4F40F1;
}`],
})
export class PortalDatasetGraphDpljpprrComponent implements OnInit {
    private mode = "lightred"
    private tongue = [
        { id: "lightred", name: "담홍지수" },
        { id: "coated", name: "설태지수" },
        { id: "bluepurple", name: "청자지수" },
        { id: "toothmask", name: "치흔지수" },
    ];
    private myChart = null;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        if (!this.dataset_id) {
            this.dataset_id = "yd5m3OpaRuAbm2dfRyh7ks2LT4e5vqsZ";
        }
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        this.labels = data.label;
        this.datas = data.value;

        await this.service.render();
    }

    private async loadGraph(mode) {

        if (mode) {
            this.mode = mode;
        }

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.destroy();
        }

        let data_pie;
        if (this.mode == "lightred" || this.mode == "coated") {
            this.colors = ['#3B29EC', '#8F86F4', '#E8E5FD'];
            if (this.mode == "lightred") {
                data_pie = this.datas.lightred;
                this.labels = ['담홍지수 - 담백설', '담홍지수 - 담홍설', '담홍지수 - 홍설'];
            }
            else {
                data_pie = this.datas.coated;
                this.labels = ['설태지수 - 박태', '설태지수 - 정상', '설태지수 - 후태'];
            }
        }

        else {
            this.colors = ['#E81841', '#FAD1D9'];
            if (this.mode == "bluepurple") {
                data_pie = this.datas.bluepurple;
                this.labels = ['청자지수 - 정상', '청자지수 - 청자설'];
            }
            else {
                data_pie = this.datas.toothmask;
                this.labels = ['치흔지수 - 정상', '치흔지수 - 강함'];
            }
        }

        const total = data_pie.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        const data = {
            labels: this.labels,
            datasets: [{
                data: data_pie,
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                pointStyle: 'circle'
            }]
        };

        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            usePointStyle: true
                        }
                    },
                    // title: {
                    //     display: true,
                    //     text: 'Total - ' + total,
                    //     align: 'end',
                    //     font: { size: '18px' }
                    // },
                    datalabels: {
                        color: 'white',
                        font: {
                            weight: 'bold',
                            size: '14px'
                        },
                        padding: 6,
                        formatter: (value) => {
                            const percentage = (value / total) * 100;
                            return percentage.toFixed(1) + '%';
                        },
                    }
                }
            }
        };
        const elmt = document.getElementById('widget-tongue');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            ctx.canvas.width = 875;
            ctx.canvas.height = 400;
            this.myChart = new Chart(ctx, config);
        }

        await this.service.render();
    }
}

export default PortalDatasetGraphDpljpprrComponent;