import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explaination.information');
import { OnInit, OnChanges, Input, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explaination-information',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.detail.explaination.information/view.scss */
span {
  font-size: 24px;
  font-family: "MAIN-B";
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

.button_control {
  position: relative;
  margin: 30px 0 0 5px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.button_control:hover .tooltip {
  opacity: 1;
}
.button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.button_control .tooltip {
  position: absolute;
  top: -60px;
  left: 150px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

img {
  width: 1720px;
  margin-left: 25px;
  margin-top: 30px;
}

.table {
  width: 1700px;
  max-height: 320px;
  font-size: 16px;
  font-family: "MAIN-M";
  margin-left: 30px;
  margin-top: 20px;
  border-radius: 10px;
}
.table tbody {
  background: none;
  vertical-align: middle;
}
.table tbody tr {
  max-height: 40px;
}
.table tbody th {
  width: 200px;
  max-height: 40px;
  background: #EDEEF8;
}
.table tbody td {
  width: 660px;
}`],
})
export class PortalDetailExplainationInformationComponent implements OnInit, OnChanges {
    @Input() list: any;
    @Input() version: any;
    @Input() page: any;
    @Input() num: any;

    public dataRow: any;
    public deviceRow: any;
    public dataInfo: any;
    public deviceInfo: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        if (this.page == 'device') {
            await this.service.init();
            await this.file_load();
            await this.file_load2();
            await this.service.render();
        }
        else {
            await this.service.init();
            await this.file_load2();
            await this.service.render();
        }
    }

    public async ngOnChanges({ version }) {
        if (!version) return;
        const { currentValue } = version;

        if (['all', 'raw', 'cleansing', 'synthetic'].includes(currentValue)) {
            this.version = currentValue;
            this.file_load();
            this.file_load2();
        }
    }

    public async file_load() {
        this.dataset_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        let { code, data } = await wiz.call("file_info", { "id": this.dataset_id, "category": this.category, "version": this.version });
        this.dataInfo = data.dataInfo;
        this.deviceInfo = data.deviceInfo;
        this.deviceRow = this.deviceInfo["all"];

        if (this.version !== "all") {
            this.deviceRow = "-";
            await this.service.render();
            return
        }
  
        this.deviceRow = this.deviceRow.toLocaleString();
        await this.service.render();
    }


    public async file_load2() {
        if (this.list.datatype == '기기') {
            const { code, data } = await wiz.call("device_info", { datacode: this.list.datacode, version: this.version });
            this.dataRow = data.sum_info
            await this.service.render();
        } else if (this.list.datatype == '설문') {
            this.dataset_id = WizRoute.segment.id;
            let { code, data } = await wiz.call("survey_info", { 'dataset_id': this.dataset_id });
            this.dataRow = data.sum_info;
            await this.service.render();
        }
    }

}

export default PortalDetailExplainationInformationComponent;