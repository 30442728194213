import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.qugbqbbv');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dataset-graph-qugbqbbv',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.dataset.graph.qugbqbbv/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphQugbqbbvComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        if (code == 200) {
            this.labels = data.labels;
            this.uro = data.uro;
            this.glu = data.glu;
            this.bil = data.bil;
            this.ket = data.ket;
            this.uri = data.uri;
            this.hem = data.hem;
            this.ph = data.ph;
            this.pro = data.pro;
            this.leu = data.leu;

        }
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                type: 'line',
                label: '우로빌리노겐',
                data: this.uro,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#4F40F1'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '포도당',
                data: this.glu,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#B0B0E8'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '빌리루빈',
                data: this.bil,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#F2748D'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '케톤체',
                data: this.ket,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#ED4264'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '요비중',
                data: this.uri,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#F2A3B3'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '잠혈',
                data: this.hem,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#3FBE7A'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: 'PH',
                data: this.ph,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#1FA2FF'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '요단백',
                data: this.pro,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#12D8FA'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '백혈구',
                data: this.leu,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#FF794F'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }]
        };

        const config = {
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 40,
                plugins: {
                    legend: {
                        display: true,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 45,
                        ticks: {
                            stepSize: 5
                        },
                        title: {
                            display: false,
                            text: '수치'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: '연령대'
                        }
                    }
                }
            },
        };
        const elmt = document.getElementById('widget-graph');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphQugbqbbvComponent;