import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.join');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-join',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.join/view.scss */
.login-view {
  background: #F7F7FA;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
@media (max-width: 768px) {
  .login-view {
    padding: 24px;
    display: block;
  }
}
.login-view .div {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.login-view .contract {
  border-radius: 10px;
}
.login-view .contract img {
  width: 70%;
}
.login-view .contract .card-footer {
  border-radius: 0 0 10px 10px;
  background-color: white;
}
.login-view .contract .card-footer label {
  font-size: 15px;
  align-content: center;
  font-family: "MAIN-B";
}
.login-view .btn-next {
  width: 30%;
  background-color: #4F41F1;
  color: white;
  display: block;
}
.login-view .container {
  max-width: 900px;
  padding-top: 48px;
  padding-bottom: 48px;
}
.login-view .logo {
  padding-bottom: 24px;
}
.login-view .logo img {
  width: 100%;
  max-width: 350px;
}
@media (max-width: 768px) {
  .login-view .logo img {
    padding-left: 24px;
    padding-right: 24px;
    display: block;
  }
}
.login-view .cards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 48px;
}
.login-view .cards .card {
  margin: 12px;
  text-align: center;
  width: 100%;
  max-width: 460px;
  display: flex;
}
.login-view .cards .card .card-body {
  padding: 48px;
}
.login-view .cards .card .card-footer {
  background-color: #DEE4F5;
  font-size: 12px;
  border-radius: 0 0 5px 5px;
  padding: 12px 72px;
}
.login-view .addition-info {
  margin-top: 24px;
  font-size: 14px;
  display: block;
}
.login-view .addition-info a {
  color: var(--wiz-color-text);
  cursor: pointer;
}
.login-view .addition-info a:hover {
  color: #6380de;
}
.login-view .underline-text-input-group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
  /* reset input */
}
.login-view .underline-text-input-group input {
  line-height: 36px;
}
.login-view .underline-text-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wc-black);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: transparent;
}
.login-view .underline-text-input-group .underline-text-input-field:placeholder-shown ~ .underline-text-input-label {
  font-size: 18px;
  cursor: text;
  top: 20px;
  color: var(--wiz-color-gray);
}
.login-view .underline-text-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wiz-color-blue);
}
.login-view .underline-text-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wiz-color-blue);
  font-weight: 700;
}
.login-view .underline-text-input-group .underline-text-input-field:required, .login-view .underline-text-input-group .underline-text-input-field:invalid {
  box-shadow: none;
}
.login-view .underline-text-onlyform-input-group {
  position: relative;
  padding: 15px 0 6px 0;
  margin-top: 10px;
  width: 100%;
  font-weight: 700;
  border-width: 3px;
  border-color: var(--wiz-color-gray);
}
.login-view .underline-text-onlyform-input-group input {
  line-height: 36px;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  font-size: 15px;
  color: #000000;
  font-weight: 700;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--wc-gray);
  outline: 0;
  font-size: 18px;
  color: #707070;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field::placeholder {
  font-size: 13px;
  color: var(--wiz-color-gray);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus {
  padding-bottom: 6px;
  font-weight: 300;
  border-width: 3px;
  border-color: var(--wiz-color-blue);
}
.login-view .underline-text-onlyform-input-group .underline-text-input-field:focus ~ .underline-text-input-label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 15px;
  color: var(--wiz-color-blue);
  font-weight: 700;
}
.login-view .error-focus .underline-text-input-field:focus {
  border-color: var(--wiz-color-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus ~ .underline-text-input-label {
  color: var(--wiz-color-highlight) !important;
}
.login-view .error-focus .underline-text-input-field:focus::placeholder {
  color: var(--wiz-color-sub-red) !important;
}
.login-view .error-msg {
  color: red;
  font-size: 12px;
}
.login-view .btn {
  padding: 14px 16px;
  border-radius: 10px;
  height: auto;
}
.login-view .kafe-login img {
  max-width: 247px;
}
.login-view .kafe-login .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-view .btn-kafe {
  background-color: #2bb7eb;
  color: white;
  border: none;
}
.login-view .btn-kafe:hover {
  background-color: #27a4d1;
}

.join-card .card-body {
  width: 70%;
  min-width: 300px;
  max-width: 470px;
  margin: 0 auto;
}

.addition-info {
  font-size: 14px;
}
.addition-info a:hover {
  color: #6380de;
}

.btn-ins {
  background-color: #EFF4FE;
}

.only-label {
  font-size: 15px;
  color: #000;
  font-weight: 700;
}

.kafe-logo {
  max-width: 247px;
}

a.text-muted {
  text-decoration: underline;
}

.btn-join {
  background: #4F41F1;
  color: white;
  font-size: 17px;
  font-weight: bold;
}`],
})
export class PageJoinComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        await this.service.init();
        // await this.service.auth.allow(false, '/main');
        await this.service.render();
    }

    public step: number = -2;
    public agree = false;

    public async move(step: number = 0) {
        this.step = step;
        await this.service.render();
    }

    // 이메일 인증
    public status: any = 0;

    public data: any = {
        mail: '',
        code: ''
    };
    public userdata: any = {
        mail: '',
        name: '',
        password: '',
        password_repeat: ''
    };

    public async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    public async check(mail: any) {
        let { code, data } = await wiz.call("check", { mail });
        this.userdata.mail = mail
        if (code == 200) {
            await this.alert("인증번호가 전송되었습니다. 이메일을 확인해주세요.", "success");
            this.status = 1;
            await this.service.render();
            return;
        }
        await this.alert(data);
    }

    public async resend(mail: any) {
        let { code, data } = await wiz.call("resend", { mail });
        if (code == 200) {
            await this.alert("인증번호가 전송되었습니다.", "success")
            return;
        }
        await this.alert(data);
    }

    public async verify(mail: any, vcode: any) {
        let { code, data } = await wiz.call("verify", { mail, code: vcode });
        if (code == 200) {
            await this.alert("인증되었습니다. 회원가입 절차를 진행해주세요.", "success");
            await this.service.auth.init();
            if (!this.service.auth.session.verified)
                return;

            this.data.mail = this.service.auth.session.verified;
            await this.service.render();
            this.move(0);
            return;
        }
        await this.alert(data);
    }

    // public async step(step) {
    //     this.step = step;
    //     await this.service.render();
    // }

    public async join() {
        let password = this.userdata.password;
        let password_re = this.userdata.password_repeat;

        if (password.length < 8) return await this.alert("8글자 이상의 비밀번호를 설정해주세요");
        if (/(\d)\1{2}/.test(password)) return await this.alert("비밀번호에 연속된 숫자 3개 이상 포함할 수 없습니다");
        if (password.search(/[a-z]/i) < 0) return await this.alert("비밀번호에 알파벳을 포함해주세요");
        if (password.search(/[0-9]/) < 0) return await this.alert("비밀번호에 숫자를 포함해주세요");
        if (password != password_re) return await this.alert("비밀번호가 일치하지 않습니다");

        let user = JSON.parse(JSON.stringify(this.userdata));
        delete user.password_repeat;
        // delete user.mail;

        user.password = this.service.auth.hash(user.password);

        let { code } = await wiz.call("join", user);

        if (code != 200) {
            await this.alert("잘못된 접근입니다");
            location.href = "/";
        }

        // await this.alert("회원가입이 완료되었습니다. 로그인을 해주세요.", "success");
        await this.service.render();
        location.href = "/auth/login";
    }
}

export default PageJoinComponent;