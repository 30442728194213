import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.modal.introduce');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-modal-introduce',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.modal.introduce/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
}
.wiz-modal .modal-content {
  padding: 20px;
  width: 600px;
  max-height: 955px;
  overflow-y: auto;
  background-color: #EAF0FB;
}
.wiz-modal .modal-content .title {
  font-family: SUIT;
  font-size: larger;
  font-weight: 600;
  margin-bottom: 10px;
}
.wiz-modal .modal-content .table-responsive {
  background: #EAF0FB;
  border-radius: 20px;
  max-height: 800px;
  padding: 20px;
}
.wiz-modal .modal-content a {
  cursor: pointer;
  color: black;
  font-size: 16px;
}
.wiz-modal .modal-content a:hover {
  font-weight: bold;
  color: #6758F0;
}
.wiz-modal .modal-full {
  width: auto;
  position: fixed;
  inset: 30px 40px;
  right: auto;
  left: auto;
  overflow: auto;
}
.wiz-modal .modal-full::-webkit-scrollbar-thumb {
  background-color: #C9C5F0;
}

.btn-hide {
  background: #6758F0;
  color: white;
}`],
})
export class ComponentModalIntroduceComponent implements OnInit {
    public isshowOne: boolean = true;
    public isshowTwo: boolean = true;

    public fullscreen: boolean = false;
    public src: any;

    public checkQR = false;
    public checkIntro = false;

    public show = false;

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        await this.service.init();
        await this.getStorage();
        await this.service.render();
        if (this.isshowOne || this.isshowTwo) this.show = true;
    }

    public async hideOne() {
        this.isshowOne = false;
        if (this.checkIntro) this.setStorage("intro");
        await this.service.render();
    }

    public async hideTwo() {
        this.isshowTwo = false;
        if (this.checkQR) this.setStorage("qr");
        await this.service.render();
    }

    public async full(src) {
        this.fullscreen = true;
        this.src = src;
        await this.service.render();
    }

    public async origin() {
        this.fullscreen = false;
        await this.service.render();
    }

    private setStorage(mode) {
        let date = new Date();
        date.setDate(date.getDate() + 6);
        let dates = date.setHours(23, 59, 59);
        if (mode == "intro") localStorage.setItem("intro", dates);
        else if (mode == "qr") localStorage.setItem("qr", dates);
    }

    private getStorage() {
        let now = new Date().getTime();
        this.isshowOne = !localStorage.getItem("intro") || localStorage.getItem("intro") < now;
        this.isshowTwo = !localStorage.getItem("qr") || localStorage.getItem("qr") < now;
    }
}

export default ComponentModalIntroduceComponent;