import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explanation.inquire');
import { OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explanation-inquire',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.detail.explanation.inquire/view.scss */
.title {
  font-size: 24px;
  font-family: "MAIN-B";
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

.phrases {
  margin-top: 10px;
  margin-left: 30px;
  color: #EB003B;
  font-size: 14px;
}

.button_control {
  position: relative;
  margin-top: 5px;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.button_control:hover .tooltip {
  opacity: 1;
}
.button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.button_control .tooltip {
  position: absolute;
  top: -40px;
  left: 250px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  font-family: "MAIN-B";
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}

.container-filter {
  max-width: 1720px;
  width: 100%;
  height: 300px;
  margin: auto;
}
.container-filter .card .card-body {
  max-width: 1420px;
  width: 100%;
}
.container-filter .card .card-body .black {
  color: black;
}
.container-filter .card .card-body .btn {
  width: 200px;
  height: 40px;
  margin-top: 10px;
  position: relative;
  border: 1px solid #D2D4DA;
  border-radius: 10px;
  color: #777986;
}
.container-filter .card .card-body i {
  margin-left: auto;
}
.container-filter .card .card-body .btn-opt {
  width: 70px;
  height: 30px;
  margin: 10px 0 0 103px;
  color: white;
  background-color: #4F40F1;
  font-size: 16px;
  border: none;
}
.container-filter .card .card-body .choice {
  border-color: #4F40F1;
  z-index: 100000;
}
.container-filter .card .card-body .list-box {
  position: absolute;
  border: 1px solid #4F40F1;
  border-top: none;
  border-radius: 5px;
  max-width: 200px;
  padding: 0;
  background-color: white;
  top: 83px;
  overflow-y: auto;
  max-height: 372px;
  z-index: 10000;
}
.container-filter .card .card-body .list-box ul {
  list-style-type: none;
  padding: 13px;
  margin: 0;
}
.container-filter .card .card-body .list-box li {
  border-bottom: 1px solid #D2D4DA;
}
.container-filter .card .card-body .list-box li:last-child {
  border-bottom: none;
}
.container-filter .card .card-body .list-box .form-check span {
  padding-top: 8px;
}
.container-filter .card .card-body .list-box .check {
  color: #4F40F1;
}
.container-filter .card .card-body .custom-line {
  width: 100%;
  border-bottom: 1px solid #DCE0E5;
  margin: 0 auto;
  padding-bottom: 30px;
}
.container-filter .card .card-body span {
  color: #777986;
  font-size: 16px;
  font-weight: bold;
}
.container-filter .card .card-body p {
  margin: auto;
}
.container-filter .card .card-body input {
  margin-top: 10px;
  border: 1px solid #D2D4DA;
}
.container-filter .table {
  font-size: 20px;
  font-family: "MAIN-M";
  width: 97%;
  height: 200px;
}
.container-filter .table tr td {
  border-right: 1px solid #D2D4DA;
}
.container-filter .table tr td:first-child {
  border-left: 1px solid #D2D4DA;
}
.container-filter .table tr th:first-child {
  border-left: 1px solid #D2D4DA;
}
.container-filter .table td {
  width: 16.6%;
}
.container-filter .result {
  padding: 0 20px;
}
.container-filter .result .require-opt .btn {
  height: 24px;
  color: #4F40F1;
  border: 1px solid #4F40F1;
  border-radius: 5px;
}
.container-filter .btn-reset {
  border: none;
  background-color: white;
  color: #777986;
}
.container-filter .btn-reset:hover {
  color: #D2D4DA;
  text-decoration: underline;
}
.container-filter .btn-save {
  width: fit-content;
  background: #6758F0;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #E3E6EC;
  font-weight: bold;
  font-size: 16px;
}

.result-card {
  align-items: center;
  border: none;
  background-color: #F7F7FA;
}
.result-card p {
  margin: 30px 30px 0;
  color: #777986;
  font-size: 16px;
  font-weight: bold;
}
.result-card .btn-go {
  width: 210px;
  background: #E5E2F5;
  color: #4F40F1;
  border-radius: 10px;
  font-weight: bold;
  font-size: 16px;
}

.form-control {
  border-radius: 10px;
}

.form-label {
  font-size: 16px;
  font-family: "MAIN-M";
}

.filter-area label.form-check > * {
  display: inline-block;
  vertical-align: middle;
}

.form-check-label {
  font-size: 16px;
  font-family: "MAIN-N";
  color: #5B5D6B;
  cursor: pointer;
}

.form-check-input {
  border: 1.5px solid #5B5D6B;
  border-radius: 2px;
}

.container-radio {
  font-size: 18px;
  font-family: "MAIN-N";
}
.container-radio .btn {
  border: solid 1px #EDEDED;
  border-radius: 0%;
  color: #777986;
  background-color: #EDEDED;
  cursor: pointer;
}
.container-radio .check {
  color: #4F40F1;
  background-color: white;
  border-bottom: 2px solid #4F40F1;
  font-weight: bold;
}
.container-radio #radio-0 {
  border-radius: 10px 0 0 0;
}
.container-radio .last-item {
  border-radius: 0 10px 0 0;
}

input[type=checkbox]:checked {
  background-color: #4F40F1;
}

.form-check-input:checked {
  color: #4F40F1;
}

.form-check-label:checked {
  color: #4F40F1;
}

.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 10000000;
  pointer-events: none;
}`],
})
export class PortalDetailExplanationInquireComponent implements OnInit, OnChanges {
    @Output() filterResult = new EventEmitter<object>();
    @Output() offcanvas = new EventEmitter<boolean>();
    @Output() modeResult = new EventEmitter<string>();
    @Input() version: any;
    @Input() page: any;

    public period = [];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public ages: any;

    public userRole = '';
    public test = false;
    public num: any;

    public filter = {
        period: {},
        age: {},
        sex: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };

    public versions = [
        { title: "재현 데이터", version: "synthetic" }
    ]
    public mode = "synthetic";
    public modeTitle = "재현 데이터"
    public periodTF = false;
    public sexTF = false;
    public ageTF = false;

    public session: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        if (this.page == "device") {
            await this.service.init();
            this.session = this.service.auth.session;
            this.dataID = WizRoute.segment.id;
            this.userRole = this.session.role;
            let datasetIDs = ['k1pkjQJFhmmC9sSXCt23g4SAvKYtVDp1', '1u6bIfRh0eEszIGuOjMDgYGatbciEWnG', 'gUz3zXDvXdTx1yjoiJonX6IuiLotqYJ3', 'exZVO96BOWuB8wnUiOVslVUaeSCb72UE', 'Giesy5Us4z6EekMlx12HcAfalV1JYasA'];
            this.inquire = document.getElementsByName('inquire');

            if (!datasetIDs.includes(this.dataID)) {
                this.versions.splice(0, 0, { title: "정제 데이터", version: "cleansing" });
            }
            await this.service.render();
            this.inquire[0].checked = true;
            this.mode = this.versions[0].version;
            this.modeTitle = this.versions[0].title;
            await this.years();
            await this.service.render();
        }

        else {
            await this.service.init();
            await this.service.render();
        }
    }

    public year = [];
    public async years() {
        const { data } = await wiz.call("years", { mode: this.mode, id: this.dataID, role: this.userRole });
        this.period = data;

    }

    public async result() {

        let copyFilter = JSON.stringify(this.filter);
        await this.loading(true);
        const { code, data } = await wiz.call("result", { id: this.dataID, filter: copyFilter, mode: this.mode, role: this.userRole });
        if (code == 201) {
            await this.loading(false);
            await this.alert("필터를 선택해주세요");
            return
        }
        this.num = data;
        this.test = true;
        await this.service.render();
        await this.loading(false);
    }

    public ngOnChanges({ version }) {
        if (!version) return;
        const { currentValue } = version;
        if (['all', 'raw', 'cleansing', 'synthetic'].includes(currentValue)) {
            this.version = currentValue;
            this.service.render();
        }

    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async push() {
        this.filterResult.emit(this.filter);
        this.offcanvas.emit(true);
        this.modeResult.emit(this.mode);
        await this.service.render();
    }

    public async changeV() {
        for (let i = 0; i < 3; i++) {
            if (this.inquire[i].checked) {
                this.mode = this.versions.find(item => item.title === this.inquire[i]['labels'][0]['innerText'])?.version;
                break;
            }
        }
        await this.service.render();

        this.optList = [];
        this.modeTitle = this.versions.find(item => item.version === this.mode)?.title;

        this.periodTF = false;
        this.sexTF = false;
        this.ageTF = false;

        this.showAge = false;
        this.showSex = false;
        this.showYear = false;

        if (this.mode != "synthetic") {
            for (let year of this.period) {
                this.filter.period[year] = this.periodTF;
            }
        }
        for (let fm of this.sex) {
            this.filter.sex[fm] = this.sexTF;
        }
        for (let birth of this.age) {
            this.filter.age[birth] = this.ageTF;
        }
        await this.service.render();
    }

    public optList = [];

    public async changeAll(event: any, category) {
        if (category == "period") {
            for (let year of this.period) {
                this.filter.period[year] = this.periodTF;
                if (!event) {
                    this.optList = this.optList.filter(item => !this.period.includes(item));
                } else {
                    if (!this.optList.includes(year)) {
                        this.optList.push(year);
                    }
                }
            }
        } else if (category == "sex") {
            for (let fm of this.sex) {
                this.filter.sex[fm] = this.sexTF;
                if (!event) {
                    this.optList = this.optList.filter(item => !this.sex.includes(item));
                } else {
                    if (!this.optList.includes(fm)) {
                        this.optList.push(fm);
                    }
                }
            }
        } else if (category == "age") {
            for (let birth of this.age) {
                this.filter.age[birth] = this.ageTF;
                if (!event) {
                    this.optList = this.optList.filter(item => !this.age.includes(item));
                } else {
                    if (!this.optList.includes(birth)) {
                        this.optList.push(birth);
                    }
                }
            }
        }
        await this.service.render();

    }

    public async add(text, opt) {

        if (text != '') {
            if (opt === 'height1') {
                text = `${text}CM ~`;
            } else if (opt === 'height2') {
                text = `~ ${text}CM`;
            } else if (opt === 'weight1') {
                text = `${text}KG ~`;
            } else if (opt === 'weight2') {
                text = `~ ${text}KG`;
            }

            const index = this.optList.indexOf(text);

            if (index === -1) {
                this.optList.push(text);
                await this.service.render();
            } else {
                this.optList.splice(index, 1);
                await this.service.render();
            }
        }
    }

    public async remove(text) {
        this.optList = this.optList.filter(item => item !== text);

        if (this.filter.period.hasOwnProperty(text)) {
            delete this.filter.period[text];
            this.periodTF = false;
        }

        if (this.filter.age.hasOwnProperty(text)) {
            delete this.filter.age[text];
            this.ageTF = false;
        }
        if (this.filter.sex.hasOwnProperty(text)) {
            delete this.filter.sex[text];
            this.sexTF = false;
        }

        if (typeof text === 'string') {
            if (text.match(/^\d+CM\s*~$/)) {
                text = text.replace(/CM\s*~$/, '');
            } else if (text.match(/^~\s*\d+CM$/)) {
                text = text.replace(/^~\s*|\s*CM$/g, '');
            } else if (text.match(/^\d+KG\s*~$/)) {
                text = text.replace(/KG\s*~$/, '');
            } else if (text.match(/^~\s*\d+KG$/)) {
                text = text.replace(/^~\s*|\s*KG$/g, '');
            }
        }

        if (this.filter.height1 === text) {
            this.filter.height1 = "";
        }
        if (this.filter.height2 === text) {
            this.filter.height2 = "";
        }
        if (this.filter.weight1 === text) {
            this.filter.weight1 = "";
        }
        if (this.filter.weight2 === text) {
            this.filter.weight2 = "";
        }

        await this.service.render();
    }


    public selectOpt(filterKey) {
        const selectedItems = Object.keys(this.filter[filterKey]).filter(key => this.filter[filterKey][key]);
        const length = this[filterKey].length;
        if (selectedItems.length === 0) {
            return '선택';
        } else if (selectedItems.length === length) {
            return '전체';
        } else if (selectedItems.length === 1) {
            return `${selectedItems[0]}`;
        } else {
            return `${selectedItems[0]} 외 ${selectedItems.length - 1}`;
        }
    }

    public async changeHide(category) {
        if (category == "period") {
            this.periodTF = false;
        } else if (category == "sex") {
            this.sexTF = false;
        } else if (category == "age") {
            this.ageTF = false;
        }
        await this.service.render();
    }

    public opt: any;
    public showYear = false;
    public showSex = false;
    public showAge = false;

    public async listClick(opt) {
        if (opt == "period") {
            this.showYear = !this.showYear;
            this.showSex = false;
            this.showAge = false;
        } else if (opt == "sex") {
            this.showSex = !this.showSex;
            this.showAge = false;
            this.showYear = false;
        } else if (opt == "age") {
            this.showAge = !this.showAge;
            this.showSex = false;
            this.showYear = false;
        }
        await this.service.render();
    }

    public async reset() {
        this.filter = {
            period: {},
            age: {},
            sex: {},
            height1: "",
            height2: "",
            weight1: "",
            weight2: "",
        };
        this.optList = [];

        this.showAge = false;
        this.showSex = false;
        this.showYear = false;

        this.num = null;
        await this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

}

export default PortalDetailExplanationInquireComponent;