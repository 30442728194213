import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dataset.explanation');
import { OnInit, Input, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, Event, NavigationEnd } from '@angular/router';
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-page-dataset-explanation',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/page.dataset.explanation/view.scss */
.container-full {
  min-width: 1920px;
  height: 100%;
  overflow: auto;
  position: relative;
}
.container-full .container-sub {
  width: 100%;
  background: no-repeat center/100% url("/assets/images/dashboard/bubble.png");
}
.container-full .container-sub .container-image {
  width: 1920px;
  height: 480px;
  margin-left: auto;
  margin-right: auto;
}
.container-full .container-sub .container-image .content-menu {
  padding-top: 70px;
  padding-left: 72px;
}
.container-full .container-sub .container-image .content-menu-name {
  padding-left: 72px;
  padding-top: 15px;
}
.container-full .container-sub .container-image .content-menu-name {
  padding-left: 72px;
  padding-top: 15px;
}
.container-full .container-sub .container-image .content-content {
  padding-left: 72px;
  padding-top: 20px;
  width: 1100px;
}
.container-full .container-sub .container-image .content-info {
  padding-left: 72px;
  padding-top: 20px;
  font-size: 15px;
}
.container-full .container-sub .container-image .content-button {
  padding-left: 72px;
  padding-top: 40px;
}
.container-full .container-sub .container-image .content-button .btn {
  border-radius: 999px;
  font-family: "MAIN-M";
  height: 45px;
  border: none;
  font-size: 20px;
  font-weight: bold;
}
.container-full .container-sub .container-image .content-button .btn-data {
  background: #4F40F1;
  color: #FFFFFF;
  width: 255px;
}
.container-full .container-sub .container-image .content-button .push {
  color: #4F40F1;
  border: 1px solid #4F40F1;
}
.container-full .container-sub .container-image .content-button .info {
  background-color: white;
}
.container-full .container-sub .container-image .content-button .info:hover {
  border: 1px solid #4F40F1;
  color: #4F40F1;
}
.container-full .container-radio {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-radio .device-radio {
  width: 1780px;
  height: 80px;
  margin: auto;
  margin-top: 30px;
}
.container-full .container-radio label {
  font-weight: normal;
  font-size: 24px;
}
.container-full .container-radio .blue {
  font-weight: bold;
  background-color: #E5E2F5;
  color: #4F40F1;
  border-bottom: 4px solid #4F40F1;
}
.container-full .container-radio #radio-0 {
  border-radius: 10px 0 0 0;
}
.container-full .container-radio .last-item {
  border-radius: 0 10px 0 0;
}
.container-full .container-radio .card {
  width: 65px;
  height: fit-content;
  align-items: center;
  border-color: #EB003B;
  color: #EB003B;
  background-color: rgba(235, 0, 59, 0.05);
}
.container-full .card {
  width: 65px;
  height: fit-content;
  align-items: center;
  border-color: #EB003B;
  color: #EB003B;
  background-color: rgba(235, 0, 59, 0.05);
}
.container-full .syn-noti {
  height: 50px;
  align-items: center;
  color: #EB003B;
}
.container-full .container-sub2 {
  width: 100%;
  background: #F7F7FA;
}
.container-full .container-sub2 .container-explanation {
  background: #F7F7FA;
  max-width: 1920px;
  margin: auto;
}
.container-full .container-sub2 .container-explanation .container-data {
  width: 1780px;
  background: white;
  margin: auto;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #E3E6EC;
}
.container-full .container-sub2 .container-explanation .container-graph {
  width: 875px;
  height: 569px;
  background: white;
  box-shadow: 0px 3px 24px #E3E6EC;
  border-radius: 10px;
  margin-top: 30px;
}
.container-full .container-sub2 .container-explanation .data-information {
  width: 1780px;
  height: 100%;
  background: white;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0px 3px 24px #E3E6EC;
}

.text-menu {
  color: #5B5B6D;
  font-size: 16px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-menu-name {
  color: black;
  font-size: 52px;
  font-weight: bold;
  font-family: "MAIN-B";
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: none;
}

.text-content {
  color: #404252;
  font-size: 22px;
  line-height: 180%;
  word-break: keep-all;
}

.box-shadow {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.btn-down {
  background: #4F40F1;
  border-radius: 34px;
  color: white;
}

.btn-request {
  background-color: #E2E4F0;
  color: var(--wiz-color-navy);
  border-radius: 10px;
  border: 1px solid #E2E4F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-allow {
  background-color: #64CF7C;
  color: white;
  border-radius: 10px;
  border: 1px solid #64CF7C;
  font-weight: bold;
  pointer-events: none;
}

.btn-reject {
  background-color: #F0142F;
  color: white;
  border-radius: 10px;
  border: 1px solid #F0142F;
  font-weight: bold;
  pointer-events: none;
}

.btn-process {
  background-color: #C9C5F0;
  color: black;
  border-radius: 10px;
  border: 1px solid #C9C5F0;
  font-weight: bold;
  pointer-events: none;
}

.btn-form {
  border: none;
  color: #4F40F1;
  background-color: white;
}

label {
  font-weight: bold;
}

.offcanvas {
  width: 31%;
  overflow: auto;
}
.offcanvas::-webkit-scrollbar-thumb {
  background-color: #C9C5F0;
}

.gray {
  color: #777986;
}

.warn {
  margin: 4px 10px;
  color: red;
}

li button {
  padding: 10px 25px;
}

.list-card {
  cursor: pointer;
}

.list-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card:hover {
  transform: scale(1.03);
}

.card-body {
  padding: 20px 25px;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs.nav-fill .nav-link {
  border-bottom: none;
  cursor: pointer;
  background: #F7F7F8;
  color: #777986;
  font-weight: "MAIN-M";
}
.nav-tabs.nav-fill .nav-link.active {
  border-bottom: none;
  background: white;
  color: black;
  font-family: "MAIN-B";
  text-align: center;
}
.nav-tabs.nav-fill .nav-close {
  display: flex;
  align-items: center;
  border-bottom: none;
  cursor: pointer;
  padding: 0px 12px;
  background: #F7F7F8;
}

.blank {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}

.tag {
  border: 1px solid #B0B0E8;
  color: #B0B0E8;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px;
}

th {
  background-color: #C9C5F0 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 12px !important;
}

.form-group {
  margin-bottom: 30px;
}
.form-group .form-label {
  font-size: 16px;
}
.form-group .btn {
  width: 100%;
  max-width: 47%;
  height: 40px;
  justify-content: flex-start;
  border: 1px solid #D2D4DA;
  border-radius: 10px;
  color: #777986;
  font-size: 16px;
  font-weight: normal;
}
@media (max-width: 1870px) {
  .form-group .btn {
    width: 100%;
  }
}
.form-group .btn:hover {
  color: #4F40F1;
  border-color: #4F40F1;
  font-weight: bold;
}
.form-group .btn-block {
  width: 33%;
}
.form-group .btn-upload {
  max-width: 100%;
  background-color: #4F40F1;
  border: #4F40F1;
  color: white;
}
.form-group .btn-upload:hover {
  color: white;
  font-weight: bold;
}
.form-group .form-check-input {
  width: 16px;
  height: 16px;
}
.form-group .form-check-input:checked {
  background-color: #4F40F1;
}
.form-group .check {
  color: #4F40F1;
  border-color: #4F40F1;
  font-weight: bold;
}
.form-group .control-box .form-control {
  width: 100%;
  height: 40px;
  border: 1px solid #D2D4DA;
  border-radius: 10px;
}
.form-group .no-hover {
  pointer-events: none;
  cursor: default;
}

.btn-back {
  border: none;
  box-shadow: none;
  width: fit-content;
}

.custom-line {
  width: 100%;
  border-bottom: 1px solid #DCE0E5;
  margin: 20px 0;
}

.loading-wrap {
  display: flex;
  position: fixed;
  z-index: 10000;
  inset: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
}

.disable {
  pointer-events: none;
  overflow: hidden;
  z-index: -1;
}`],
})
export class PageDatasetExplanationComponent implements OnInit {
    public dashboard_ls = [

        { url: "/dashboard/device", name: "한의 건강검진 데이터셋", content: " (기기)" },
        { url: "/dashboard/blood", name: "혈자리 데이터셋", content: " (혈자리)" },
        { url: "/dashboard/herbal_medicine", name: "한약재 데이터셋", content: " (한약재)" },
        { url: "/dashboard/medical_institution", name: "의료기관 데이터셋", content: " (의료기관)" },
        { url: "/dashboard/decoction", name: "탕약 데이터셋", content: " (탕약)" }];

    public list = [];
    public down = false;
    public columns: any;
    public mode: any; // preview/schema
    public files: any;
    public fd = new FormData();
    public post = {
        dataID: "",
        shareID: "",
        files: []
    }
    public modes = [
        { title: "정제 데이터", mode: "cleansing" },
        { title: "재현 데이터", mode: "synthetic" }
    ]
    public selectedMode = "cleansing";

    public status: any;
    public statusContent: any;
    public shareInfo = [];

    public purpose: any;
    public content: any;

    public use = true;
    public up = false;

    public period = [];
    public sex = ["여자", "남자"];
    public age = ["20-", "20-29", "30-39", "40-49", "50-59", "60+"];
    public weight1: any;
    public weight2: any;
    public height1: any;
    public height2: any;
    public filter = {
        period: {},
        age: {},
        sex: {},
        height1: "",
        height2: "",
        weight1: "",
        weight2: "",
    };

    private tab = "share";
    private listMode = "list";
    private history = [];

    public versions = [
        { title: "전체 데이터", version: "all" },
        { title: "재현 데이터", version: "synthetic" }
    ]
    public version = "all";
    private radio: any;

    public conditions = [
        { title: "신장 소숫점 자리 제거", en: "point" },
        { title: "몸무게 소숫점 자리 제거", en: "mmi" },
        { title: "성별 제거", en: "sex" },
        { title: "연령대 제거", en: "agegroup" },
        { title: "신장 제거", en: "height" },
        { title: "몸무게 제거", en: "weight" }
    ]
    public conFilter = {
        point: "", sex: "", agegroup: "", height: "", weight: "", mmi: "", detail: "",
    };

    public cleanTF = true;

    public session: any;
    public userRole: any;
    public id = '';
    public shareID = '';

    public oneMonthAfter: Date;
    public nowDate: Date;
    public down_period = '';

    public datatype = '';
    constructor(@Inject( Service)         public service: Service,@Inject( Router    )         public router: Router    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.session = this.service.auth.session;
        this.userRole = this.session.role;
        this.id = WizRoute.segment.id;
        if (Object.keys(this.session).length === 0) this.use = false;

        this.radio = document.getElementsByName('radio');

        this.mode = 'preview';
        this.dataset_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;
        this.userID = this.session.id;
        await this.loading(true);
        await this.load();
        if (this.datatype == '기기') {
            this.radio[0].checked = true;
        }

        this.currentRoute = this.router.url;
        for (let menu of this.dashboard_ls) {
            if (this.currentRoute.indexOf(menu.url) == 0) {
                this.dashboard_name = menu.name;
                this.dashboard_url = menu.url;
                break
            }
        }

        await this.service.render();

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = event.url;

                for (let menu of this.dashboard_ls) {
                    if (this.currentRoute.indexOf(menu.url) == 0) {
                        this.dataset_name = menu.name;
                        this.dataset_content = menu.content;
                        break
                    }
                }
                this.service.render();
            }
        })
        await this.loading(false);

        await this.service.render();
    }


    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load() {
        let { code, data } = await wiz.call("info", { "category": this.category, "id": this.dataset_id, "userID": this.userID, "datasetID": this.id });
        this.datatype = data.datatype;
        this.period = JSON.parse(data.period);
        this.list = data.row;
        this.list.tags = JSON.parse(this.list.tags);
        this.list.period = JSON.parse(this.list.period);
        this.list.schema = JSON.parse(this.list.schema);
        this.list.created = this.list.created.substr(0, 10);
        this.list.updated = this.list.updated.substr(0, 10);
        this.down_period = data.down_period;
        let thumbs = data.up;
        if (thumbs != null) {
            this.up = true;
        }
        if (this.id == '0oodrogb8s8thhwaf2w8j1vcrmqaeh98') {
            this.selectedMode = 'survey';
        } else if (this.id == 'rtzljjeqylxzd3lfsarkqpdru040gqut') {
            this.selectedMode = 'lifelog';
        }

        if (!this.shareInfo) {
            this.status = "nothing";
        }

        let datasetIDs = ['k1pkjQJFhmmC9sSXCt23g4SAvKYtVDp1', '1u6bIfRh0eEszIGuOjMDgYGatbciEWnG', 'gUz3zXDvXdTx1yjoiJonX6IuiLotqYJ3', 'exZVO96BOWuB8wnUiOVslVUaeSCb72UE', 'Giesy5Us4z6EekMlx12HcAfalV1JYasA'];

        // versions => 최상단 종류 / modes => 공유 신청 시 데이터셋 종류
        // 정제 X
        if (!datasetIDs.includes(this.dataset_id)) {
            this.versions.splice(1, 0, { title: "정제 데이터", version: "cleansing" });
        } else {
            this.modes.splice(1, 1);
        }
        await this.getInfo();
        await this.service.render();
    }

    private async getInfo() {
        let { code, data } = await wiz.call("shareInfo", { "id": this.dataset_id });
        this.shareInfo = data;
        if (code == 200) {
            for (let i = 0; i < this.shareInfo.length; i++) {
                if (this.shareInfo[i].filter) {
                    this.shareInfo[i].filter = JSON.parse(this.shareInfo[i].filter.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
                }
                this.shareInfo[i].tag = await this.showFilter(this.shareInfo[i].filter);
            }
        }
    }

    public async years() {
        const { data } = await wiz.call("years", { id: this.id, role: this.userRole });
        this.period = data;
    }

    public async share() {
        this.down = true;
        await this.service.render();
    }

    // 데이터 건수 조회
    public async shareFilter(e) {
        this.filter = e;
        await this.service.render();
    }
    public async offBool(o) {
        this.down = o;
        await this.service.render();
    }
    public async shareMode(m) {
        this.selectedMode = m;
        await this.service.render();
    }

    public close() {
        this.down = false;
        this.service.render();
    }

    public async request() {
        let origin = window.location.orgin;
        if (!this.purpose) {
            await this.alert("활용 목적을 선택해주세요.", "error")
        } else if (!this.content) {
            await this.alert("데이터셋 공유 요청 목적을 작성해주세요.", "error");
        } else if (this.files === undefined || this.files.length === 0) {
            await this.alert("가명/익명 정보 이용 제공 동의서를 업로드해주세요.", "error");
        } else {
            let res = await this.alert(this.list.title + " 데이터셋 공유 요청하시겠습니까?", "success", "취소");
            if (res) {

                await this.loading(true);
                let copyFilter = JSON.stringify(this.filter);
                let copyConFilter = JSON.stringify(this.conFilter);
                if (this.id == '0oodrogb8s8thhwaf2w8j1vcrmqaeh98') {
                    this.selectedMode = 'survey';
                } else if (this.id == 'rtzljjeqylxzd3lfsarkqpdru040gqut') {
                    this.selectedMode = 'lifelog';
                }
                let { code, data } = await wiz.call("request", { "userID": this.userID, "purpose": this.purpose, "content": this.content, "id": this.dataset_id, "name": this.list.title, "filter": copyFilter, "conFilter": copyConFilter, "mode": this.selectedMode, "datatype": this.datatype, "datasetID": this.id, origin: origin });
                this.shareID = data;
                await this.filepost();
                if (code == 200) {
                    await this.loading(false);
                    await this.alert("공유 심사 결과 확인은 해당 페이지 혹은 데이터셋 공유 -> 내 공유요청 목록 페이지에서 확인해주세요.", "success")
                    location.reload();
                }
            }
        }
    }

    public async download() {
        if (this.nowDate > this.oneMonthAfter) {
            await this.alert("유효기간이 만료되어 저장할 수 없습니다. 다시 공유 요청 해주십시오.")
        }
        else {
            let download = wiz.url('download?id=' + this.dataset_id + "&title=" + this.list.title + "&docID=" + this.history.id)
            window.location.href = download;
        }
    }

    public async downPdf(docId) {

        let download = wiz.url("downPdf?docId=" + docId)
        window.open(download, '_blank');
        // window.location.href = download; // 새창열기 없이 다운로드
    }



    public async drive() {

        if (this.nowDate > this.oneMonthAfter) {
            await this.alert("유효기간이 만료되어 저장할 수 없습니다. 다시 공유 요청 해주십시오.")
        }
        else {
            let res = await this.alert(this.list.title + " 데이터셋을 연구자 서랍에 저장하시겠습니까?", "success", "취소");
            if (res) {
                let { code, data } = await wiz.call("drive", { "id": this.dataset_id, "name": this.list.title, "docID": this.history.id });
                if (code == 200) {
                    await this.alert("저장되었습니다.", "success");
                }
            }
        }
    }

    private async change(mode) {
        if (mode === "list") {
            this.tab = "list";
            this.listMode = "list";
        }
        else if (mode === "share") {
            this.tab = "share";
        }
        else {
            let message = "tab을 바꾸는 과정에서 오류가 발생했습니다. 다시 시도해 주십시오."
            await this.alert(message);
        }
        await this.service.render();
    }

    private async changeMode(item: any) {
        if (this.listMode === "list") {
            this.listMode = "detail";
            this.history = item;
            this.nowDate = new Date();
            const updated = this.history.updated.split(' ')[0];
            const date: Date = new Date(updated);
            this.oneMonthAfter = new Date(date);
            switch (this.down_period) {
                case '하루':
                    this.oneMonthAfter.setDate(date.getDate() + 1);
                    break;
                case '일주일':
                    this.oneMonthAfter.setDate(date.getDate() + 7);
                    break;
                case '한달':
                    this.oneMonthAfter.setMonth(date.getMonth() + 1);
                    break;
                case '6개월':
                    this.oneMonthAfter.setMonth(date.getMonth() + 6);
                    break;
                case '1년':
                    this.oneMonthAfter.setFullYear(date.getFullYear() + 1);
                    break;
                default:
                    console.log('Invalid drive period');
                    return;
            }

            if (this.history.condition) {
                this.history.conFilter = JSON.parse(this.history.condition.replace(/'/g, '"').replace(/True/g, 'true').replace(/False/g, 'false'));
            };
            if (this.history.requirement == "raw") this.history.requirement = "Raw 데이터";
            else if (this.history.requirement == "cleansing") this.history.requirement = "정제 데이터"

            if (this.history.status == 'request') {
                this.status = "request";
                this.statusContent = "접수되었습니다.";
            } else if (this.history.status == 'process') {
                this.status = "request";
                this.statusContent = this.history.dataset_name + " 데이터셋 활용 가능 여부 심사중입니다.";
            } else if (this.history.status == 'reject') {
                this.status = "request";
                this.statusContent = this.history.dataset_name + " 데이터셋을 활용할 수 없습니다.";
            } else if (this.history.status == 'allow') {
                this.status = "download";
                this.statusContent = this.history.dataset_name + " 데이터셋을 활용할 수 있습니다.";
            }
        }
        else if (this.listMode === "detail") {
            this.listMode = "list";
        }
        else {
            let message = "List의 mode를 바꾸는 과정에서 오류가 발생했습니다. 다시 시도해 주십시오."
            await this.alert(message);
        }
        await this.service.render();
    }

    private async showFilter(filter) {
        let arr = [];
        const getTrueKeys = (obj) => Object.keys(obj).filter(key => obj[key] === true);

        let isEmpty = true;
        for (let key in filter) {
            if (typeof filter[key] === 'object' && filter[key] !== null && Object.keys(filter[key]).length > 0) {
                isEmpty = false;
                let trueKeys = getTrueKeys(filter[key]);
                for (let i = 0; i < trueKeys.length; i++) {
                    if (arr.length >= 5) {
                        break;
                    }
                    arr.push(trueKeys[i]);
                }
            } else if (typeof filter[key] === 'string' && filter[key] !== '') {
                isEmpty = false;
            }

            if (arr.length >= 5) {
                break;
            }
        }
        if (isEmpty) {
            arr.push("전체");
        }
        return arr;
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

    // 최상단 데이터셋 종류 변경
    public async changeV() {
        for (let i = 0; i < 4; i++) {
            if (this.radio[i].checked) {
                this.version = this.versions.find(item => item.title === this.radio[i]['labels'][0]['innerText'])?.version;
                this.selectedMode = this.version;
                if (this.version == "all") this.selectedMode = "synthetic"
                break;
            }
        }
        await this.service.render();
    }

    // offcanvas 내 데이터셋 종류 변경
    public async changeM() {
        for (let year of this.period) {
            this.filter.period[year] = false;
        }
        for (let fm of this.sex) {
            this.filter.sex[fm] = false;
        }
        for (let birth of this.age) {
            this.filter.age[birth] = false;
        }
        await this.service.render();
    }

    public periodTF = false;
    public sexTF = false;
    public ageTF = false;
    public requsetTH = false;

    public async changeAll(category) {
        if (category == "period") {
            for (let year of this.period) {
                this.filter.period[year] = this.periodTF;
            }
        } else if (category == "sex") {
            for (let fm of this.sex) {
                this.filter.sex[fm] = this.sexTF;
            }
        } else if (category == "age") {
            for (let birth of this.age) {
                this.filter.age[birth] = this.ageTF;
            }
        } else if (category == "request") {
            for (let key in this.conFilter) {
                if (this.conFilter.hasOwnProperty(key) && key !== "detail") {
                    this.conFilter[key] = this.requestTF;
                }
            }
        }
        await this.service.render();
    }

    public async changeHide(category) {
        if (category == "period") {
            this.periodTF = false;
            await this.service.render();
        } else if (category == "sex") {
            this.sexTF = false;
            await this.service.render();
        } else if (category == "age") {
            this.ageTF = false;
            await this.service.render();
        } else if (category == "request") {
            this.requestTF = false;
            await this.service.render();
        }
        await this.service.render();
    }

    public async back() {
        this.down = true;
        this.use = true;
        this.listMode = 'list';

        await this.service.render();
    }

    public async removeFile() {
        let res = await this.alert("삭제하시겠습니까?", "error", "취소");
        if (res) {
            let { code, data } = await wiz.call("removeFile", { filepath: this.filepath })
            if (code != 200) return
        }
        await this.alert("삭제되었습니다.", "success");
        await this.service.render();
        await this.service.render();
    }

    private async fileSelect(event) {
        this.files = event.target.files;
    }

    public async upload() {
        if (this.files === undefined || this.files.length === 0) {
            await this.alert("가명/익명 정보 이용 제공 동의서 파일을 선택해주세요.", "error");
            return
        }
        for (let i = 0; i < this.files.length; i++) {
            let file = this.files[i]
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.post.files.push(file.filepath);
        }
        await this.service.render();
    }

    public async filepost() {
        this.post.dataID = this.id;
        this.post.shareID = this.shareID;
        this.fd.append("data", JSON.stringify(this.post))
        let url = wiz.url('upload');
        let { code, data } = await this.service.file.upload(url, this.fd);
        if (code != 200) {
            await this.alert("파일 업로드 중 오류가 발생했습니다")
            return;
        }

    }

    private async delete_file(item) {
        let files = this.post.files
        let index = files.indexOf(item);
        files.splice(index, 1)
        this.post.files = files;
        await this.service.render();
    }

    public async thumbs_up() {
        this.up = !this.up;
        const { code, data } = await wiz.call("thumbs_up", { up: this.up, dataset_id: this.dataset_id, user_id: this.userID });
        if (data) {
            this.list.thumbs += 1;
        } else {
            this.list.thumbs -= 1;
        }
        await this.service.render();
    }

}

export default PageDatasetExplanationComponent;