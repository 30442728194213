import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.search.popularity');
import { OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-search-popularity',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.search.popularity/view.scss */
.br-10 {
  border-radius: 10px;
}

.card {
  width: 290px;
  height: 310px;
  border: none;
}
@media (max-width: 1919px) {
  .card {
    height: 117px;
    border: none;
  }
}
.card .card-body {
  font-size: 16px;
}
.card .card-body .title {
  color: #9496A1;
  letter-spacing: 0px;
  font-weight: bold;
}
.card .card-body tr {
  max-height: 40px !important;
  cursor: pointer;
}
.card .card-body tr:hover {
  color: #4F40F1;
  font-weight: bold;
}
.card .card-body .media td {
  font-size: 20px;
  font-weight: bold;
}`],
})
export class ComponentSearchPopularityComponent implements OnInit {
    @Input() title: any;

    private list = [];
    public web = true;
    public item = '';
    public interval: any;
    public index = null;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        this.checkScreenSize();
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            let msg = "인기 검색어를 불러오는 과정에서 오류가 발생했습니다.";
            await this.alert(msg);
            return;
        }
        this.list = data;
        await this.rotation();
        await this.service.render();
    }

    @HostListener('window:resize', ['$event'])
    public async onResize(event: Event) {
        this.checkScreenSize();
        this.rotation();
    }

    public async ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public async rotation() {
        if (this.interval) {
            clearInterval(this.interval);
        }

        if (!this.web) {
            this.index = 0;
            this.interval = setInterval(async () => {
                this.item = this.list[this.index];
                this.index = (this.index + 1) % this.list.length;
                await this.service.render();
            }, 1500);
            await this.service.render();

        }

    }

    private async checkScreenSize() {
        this.web = window.innerWidth >= 1920;
    }

    private async alert(message: string, status: string = 'error') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: false,
            actionBtn: status,
            action: "확인",
            status: status
        });
    }

    private async search(text) {
        const { code } = await wiz.call("log", { text });
        if (code !== 200) {
            let msg = "검색 과정에서 오류 발생!";
            await this.alert(msg);
            return;
        }

        let url = "/dataset/search?text=" + text;
        this.service.href(url);
    }
}

export default ComponentSearchPopularityComponent;