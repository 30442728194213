import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.survey.preview');
import { OnInit, OnChanges, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'wiz-portal-detail-survey-preview',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.detail.survey.preview/view.scss */
.container-preview {
  width: 1780px;
  height: auto;
  border-radius: 10px;
  margin: auto;
  margin-top: 30px;
  box-shadow: 0px 3px 24px #E3E6EC;
}
.container-preview .nav-tabs.nav-fill .nav-link {
  cursor: pointer;
  border: none;
  border-radius: 10px 10px 0 0;
  background: #F7F7F8;
  color: #777986;
  font-size: 24px;
  font-weight: "MAIN-M";
}
.container-preview .nav-tabs.nav-fill .nav-link.active {
  border: none;
  background: white;
  color: black;
  font-size: 24px;
  font-family: "MAIN-B";
  text-align: center;
}

.tab-content {
  width: 100%;
  height: 100%;
  border-radius: 0 0 10px 10px;
  text-align: center;
}
.tab-content .card {
  border: none;
  border-top: none;
  border-radius: 0 0 10px 10px;
}
.tab-content span {
  font-size: 24px;
  font-family: "MAIN-B";
}
.tab-content .opt {
  font-size: 15px;
}
.tab-content .blue {
  background-color: #4F40F1;
  border-color: #4F40F1;
  color: white;
}
.tab-content #radio-0 {
  border-radius: 20px 0 0 20px;
}
.tab-content .last-item {
  border-radius: 0 20px 20px 0;
}
.tab-content .list-group {
  height: 300px;
  overflow-y: scroll;
  scrollbar-color: #4F40F1 !important;
}
.tab-content .list-group ::-webkit-scrollbar {
  width: 10px !important;
}
.tab-content .list-group ::-webkit-scrollbar-thumb {
  color: #4F40F1 !important;
}
.tab-content .button_control {
  margin-top: 5px;
  position: relative;
  border: 0;
  background-color: transparent;
  vertical-align: top;
  box-shadow: none;
}
.tab-content .button_control:hover {
  background-color: rgba(255, 255, 255, 0);
}
.tab-content .button_control:hover .tooltip {
  opacity: 1;
}
.tab-content .button_control i {
  display: inline-block;
  vertical-align: top;
  opacity: 0.3;
}
.tab-content .button_control .tooltip {
  position: absolute;
  top: -40px;
  left: 170px;
  padding: 5px 12px;
  height: auto;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 15px;
  line-height: 27px;
  white-space: nowrap;
  transition: opacity 0.2s;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
}
.tab-content table {
  width: 100%;
}
.tab-content table thead {
  height: 40px;
  background: #EDEEF8;
}
.tab-content table thead th {
  font-size: 16px;
  font-family: "MAIN-B";
  color: black;
  vertical-align: middle;
  background: #EDEEF8;
  border-right: 1px solid #D2D4DA;
}
.tab-content table thead th td:last-child {
  border-right: white;
}
.tab-content table tbody tr:last-child {
  border-bottom: white;
}
.tab-content table tbody tr td {
  height: 40px;
  padding: 12px;
  border-right: 1px solid #D2D4DA;
}
.tab-content table tbody tr td:last-child {
  border-right: white;
}
.tab-content .question {
  width: 100%;
  max-height: 300px;
  overflow-y: scroll;
}
.tab-content .response {
  width: 100%;
  overflow-x: scroll;
}
.tab-content .response table {
  height: 300px;
}

.loading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 1000;
  pointer-events: none;
}`],
})
export class PortalDetailSurveyPreviewComponent implements OnInit {

    public mode = 'data';
    public opt = 'question';
    public category: any;
    public list = [
        { title: "질문", opt: "question" },
        { title: "응답", opt: "response" }
    ]
    public id = '';
    public titles = [];
    public Qinfo = [];
    public Qcol = [];
    public Rinfo = [];
    public Rcol = [];
    public PDF: any = "";

    public data = [];
    public name: any;
    public lifelog_ls = [];
    public dataType = '';

    constructor(@Inject( Service)         public service: Service,@Inject( DomSanitizer)         private sanitizer: DomSanitizer,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.id = WizRoute.segment.id;
        if (this.id == '0oodrogb8s8thhwaf2w8j1vcrmqaeh98') {
            this.dataType = 'survey';
        } else if (this.id == 'rtzljjeqylxzd3lfsarkqpdru040gqut') {
            this.dataType = 'lifelog';
        }
        await this.loading(true);
        if (this.dataType == 'survey') {
            await this.preview();
            await this.question();
        } else if (this.dataType == 'lifelog') {
            await this.lifelog();
            await this.lifeAns()
        }

        await this.loading(false);
        await this.service.render();
    }
    //라이프로그
    public schema: any;
    public async lifelog() {

        const { code, data } = await wiz.call("lifelog");
        this.titles = data.titles;
        this.schema = data.schema;
        this.category = this.titles[0];
        const categoryItems = this.schema[this.category];
        for (let key in categoryItems[0]) {
            if (categoryItems[0].hasOwnProperty(key)) {
                this.Qcol = Object.keys(categoryItems[0]);
                break;
            }
        }
        this.data = this.schema[this.category]
        await this.service.render();
    }

    public async lifeAns() {
        const { code, data } = await wiz.call("lifeAns", { "title": this.category });
        this.Rcol = data.cols;
        this.Rinfo = data.info;
        await this.service.render();
    }

    //문진 및 설문
    public async question() {
        this.category = this.titles[0];
        const { code, data } = await wiz.call("question");
        this.titles = data.titles;
        this.Qinfo = data.info;
        this.category = this.titles[0];
        for (let key in this.Qinfo) {
            if (this.Qinfo.hasOwnProperty(key)) {
                this.Qcol = Object.keys(this.Qinfo[key]);
                break;
            }
        }
        await this.info();
        await this.service.render();
    }

    public async info() {
        this.data = this.Qinfo.filter(row => row.TABLECAT.includes(this.category));
        this.Rcol = this.data.map(row => row.VARORIG)
        this.name = this.data.map(row => row.TABLECAT.split('.')[0])[0];
        const { code, data } = await wiz.call("answer", { "name": this.name, "varorig": JSON.stringify(this.Rcol) });
        this.Rinfo = data.answer;
        await this.service.render();
    }

    public async preview() {
        let url = wiz.url('pdf');
        url = `${url}?file=${'CRF_2021_2.1_PS.pdf'}`;
        let fullUrl = `https://kmbig-v4.seasonsoft.net${url}`;
        console.log("url", fullUrl)
        this.PDF = this.sanitizer.bypassSecurityTrustResourceUrl(fullUrl);
        console.log("pdf", this.PDF)
        await this.service.render();
    }

    public async tab(mode) {
        this.mode = mode;
        this.service.render();
    }

    public async changeO(opt) {
        this.opt = opt;
        this.service.render();
    }

    public async changeC(category) {
        await this.loading(true);
        this.category = category;
        if (this.dataType == 'survey') {
            await this.info();
        } else if (this.dataType == 'lifelog') {
            if (this.mode == 'data') {
                await this.lifeAns();
            } else this.data = this.schema[this.category];
        }
        await this.loading(false);
        this.service.render();
    }

    private async loading(act) {
        this._loading = act;
        await this.service.render();
    }

}

export default PortalDetailSurveyPreviewComponent;