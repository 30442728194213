import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.detail.explaination.graph');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-detail-explaination-graph',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.detail.explaination.graph/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}

img {
  width: 809px;
  height: 459px;
}`],
})
export class PortalDetailExplainationGraphComponent implements OnInit {
    @Input() title: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
    }
}

export default PortalDetailExplainationGraphComponent;