import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.gvnxalbj');
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-portal-dataset-graph-gvnxalbj',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.dataset.graph.gvnxalbj/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphGvnxalbjComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        if (code == 200) {
            this.labels = data.labels;
            this.m_avg = data.m_avg;
            this.f_avg = data.f_avg;
        }
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                label: "남성 평균",
                data: this.m_avg,
                backgroundColor: ["#00FF0000"],
                borderColor: ["#5041F2"],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                label: "여성 평균",
                data: this.f_avg,
                backgroundColor: ["#00FF0000"],
                borderColor: ["#F2748D"],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }]
        };

        const config = {
            type: 'line',
            data: data,
            options: {

                responsive: false,
                maxBarThickness: 100,
                plugins: {
                    legend: {
                        display: true,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        suggestedMin: 50,
                        suggestedMax: 90,
                        ticks: {
                            stepSize: 10
                        },
                        title: {
                            display: true,
                            text: '맥진'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: '연령대'
                        }
                    }
                }
            },
        };
        const elmt = document.getElementById('widget-graph');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }

        await this.service.render();
    }
}

export default PortalDatasetGraphGvnxalbjComponent;