import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.dataset.graph.eqhlcvye');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-portal-dataset-graph-eqhlcvye',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/portal.dataset.graph.eqhlcvye/view.scss */
span {
  font-family: "MAIN-B";
  font-size: 24px;
  color: black;
  margin-top: 30px;
  margin-left: 30px;
}`],
})
export class PortalDatasetGraphEqhlcvyeComponent implements OnInit {

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loadFile();
        await this.loadGraph();
    }

    public async loadFile() {
        this.dataset_id = WizRoute.segment.id;
        let { code, data } = await wiz.call("fileInfo", { "id": this.dataset_id });
        if (code == 200) {
            this.labels = data.labels;
            this.forehead = data.forehead_means;
            this.neck = data.neck_means;
            this.armpit = data.armpit_means;
            this.chest = data.chest_means;
            this.rib = data.rib_means;
            this.waist = data.waist_means;
            this.ilium = data.ilium_means;
            this.gg = data.gg_means;
        }
        await this.service.render();
    }

    public async loadGraph() {
        const data = {
            labels: this.labels,
            datasets: [{
                type: 'line',
                label: '이마둘레',
                data: this.forehead,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#4F40F1'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '목둘레',
                data: this.neck,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#B0B0E8'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '겨드랑이둘레',
                data: this.armpit,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#F2748D'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '가슴둘레',
                data: this.chest,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#ED4264'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '늑골둘레',
                data: this.rib,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#F2A3B3'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '허리둘레',
                data: this.waist,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#3FBE7A'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '장골둘레',
                data: this.ilium,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#1FA2FF'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }, {
                type: 'line',
                label: '곡골둘레',
                data: this.gg,
                backgroundColor: [
                    '#00FF0000'
                ],
                borderColor: [
                    '#12D8FA'
                ],
                fill: true,
                pointBackgroundColor: '#fff',
                borderWidth: 3,
            }]
        };

        const config = {
            data: data,
            options: {
                responsive: false,
                maxBarThickness: 40,
                plugins: {
                    legend: {
                        display: true,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        suggestedMin: 0,
                        suggestedMax: 100,
                        ticks: {
                            stepSize: 25
                        },
                        title: {
                            display: true,
                            text: '둘레'
                        }
                    },
                    x: {
                        title: {
                            display: true,
                            text: '전신부피'
                        }
                    }
                }
            },
        };

        const elmt = document.getElementById('widget-whb');
        if (elmt) {
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default PortalDatasetGraphEqhlcvyeComponent;