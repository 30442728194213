import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.chart.pie');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Chart } from 'chart.js/auto';

@Component({
    selector: 'wiz-component-chart-pie',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.chart.pie/view.scss */
.title {
  font-size: 18px;
  color: black;
  font-family: "MAIN-M";
  width: auto;
}

.text-num {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 12px;
}

.text-unit {
  margin-left: 5px;
  font: normal normal normal 16px/55px SUIT;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}`],
})
export class ComponentChartPieComponent implements OnInit {
    @Input() title: any;
    @Input() labels: any;
    @Input() datas: any;
    @Input() colors: any;

    constructor(@Inject( Service    )         public service: Service    ) { }

    public async ngOnInit() {
        await this.service.init();
        const data = {
            labels: this.labels,
            datasets: [{
                label: '건수별 / 연도별',
                data: this.datas,
                backgroundColor: this.colors,
                borderColor: this.colors,
                borderWidth: 1,
                pointStyle: 'circle'
            }]
        };

        const config = {
            type: 'pie',
            data: data,
            options: {
                responsive: false,
                plugins: {
                    legend: {
                        // display:false,
                        position: 'bottom',
                        labels: {
                            // pointStyle: "circle"
                            usePointStyle: true,
                        }
                    },
                }
            },
        };

        const actions = [
            {
                name: 'Toggle Point Style',
                handler(myChart) {
                    myChart.options.plugins.legend.labels.usePointStyle = !myChart.options.plugins.legend.labels.usePointStyle;
                    myChart.update();
                }
            },
        ];
        const elmt = document.getElementById('widget-pie');
        if (elmt){
            const ctx = elmt.getContext('2d');
            let myChart = new Chart(ctx, config);
        }
        await this.service.render();
    }
}

export default ComponentChartPieComponent;